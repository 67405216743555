import styled from "@emotion/styled"
import { DataGrid, ptBR, DataGridProps } from "@mui/x-data-grid"
import { memo } from "react"

const StyledGrid = styled(DataGrid)`
    .MuiDataGrid-virtualScroller {
        min-height: 52px;
    }
`

export const Table = memo((props: DataGridProps) => {
    return (
        <StyledGrid
            localeText={{
                ...ptBR.components.MuiDataGrid.defaultProps.localeText,
                noRowsLabel: 'Não há registros'
            }}
            rowSelection={false}
            {...props}
        />
    )
})
