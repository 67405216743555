import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "./components";
import RouterProvider from "./pages";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/pt-br';

function App() {
  return (
    <SnackbarProvider
      autoHideDuration={5000}
    >
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="br">
      <ThemeProvider>
        <RouterProvider />
      </ThemeProvider>
      </LocalizationProvider>
    </SnackbarProvider>
  );
}

export default App;
