import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { Admin } from "./admin";
import { Calculator } from "./calculator";
import { Home } from "./home"
import { Login } from "./login";
import { Thankyou } from "./thankyou";

export const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Home />
      ),
    },
    {
      path: "/login",
      element: (
        <Login />
      ),
    },
    {
      path: "/admin",
      element: (
        <Admin />
      ),
    },
    {
      path: "/obrigado",
      element: (
        <Thankyou />
      ),
    },
    {
      path: "/calculadora",
      element: (
        <Calculator />
      )
    }
]);
  

const Provider = () => {
    return (
      <RouterProvider router={router} />
    )
}

export default Provider