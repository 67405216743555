import React from "react";
import { Button } from "../Button";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export interface ExportExcelButtonProps {
    label: string
    fileName: string
    data: Array<{ [key: string]: string }>
    columns: Array<{ field: string, headerName: string, valueGetter?: (row: { row: { [key: string]: any} }) => string, valueFormatter?: (row: { value: string }) => string }>
    
}

export const ExportExcelButton = (props: ExportExcelButtonProps) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = () => {
        const parsedData = props.data.map(item => props.columns.reduce((prev, curr) => {
            const value = curr?.valueGetter?.({ row: item }) || item[curr.field]

            return {
                ...prev,
                [curr.headerName]: curr.valueFormatter ? curr.valueFormatter({ value }) : value
            }
        }, {}))

        const ws = XLSX.utils.json_to_sheet(parsedData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, props.fileName + fileExtension);
    }

    return (
        <Button onClick={exportToCSV}>{props.label}</Button>
    )
}